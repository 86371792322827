



$('.upload').click(function () {



    $(this).parent().find(".file-upload").click();

});


function openFileUploader() {
}

setTimeout(function () {
    $('.preloader').addClass('done');

    setTimeout(function () {
        jQuery('.preloader').hide('slow');
        $(".wow-load").addClass("animated")
    }, 2000);

}, 1000);


jarallax(document.querySelectorAll('[data-jarallax-element]'), {
    disableParallax: /iPad|iPhone|iPod|Android/,
    disableVideo: /iPad|iPhone|iPod|Android/
});


$(function () {
    if ((window.location.pathname.indexOf("index.html")) > -1) {
        $(body).addClass('active');
    }
});


// $(document).ready(function () {
//     $(".sppp").addClass("animate")
// });


if ((window.location.pathname == '/')) {
    $("body").addClass("homepage")

} else if ((window.location.pathname == '/hiforce/')) {
    $("body").addClass("homepage")
} else {
    $("body").addClass("inner-page")
}





new WOW().init();


// Splitting();

// animations

// Wrap every letter in a span



// 

var target = $('.second-nav')
target.after('<div class="affix" id="affix"></div>')

var affix = $('.affix')
affix.append(target.clone(true))

// Show affix on scroll.
var element = document.getElementById('affix')
if (element !== null) {
    var position = target.position()
    window.addEventListener('scroll', function () {
        var height = $(window).scrollTop()
        if (height > position.top) {
            target.css('visibility', 'hidden')
            affix.css('display', 'block')
        } else {
            affix.css('display', 'none')
            target.css('visibility', 'visible')
        }
    })
}


// lines


// lines


// $(".inner-page").each(function() {
//     if ((window.location.pathname.indexOf($(this).attr('href'))) > -1) {
//         $(this).parent().addClass('active');
//     }
//   });



var $owlCarousel = $(".carousel");

if ($owlCarousel.length) {
    $owlCarousel.each(function () {

        var items = parseInt($(this).attr("data-owl-items"), 10);
        if (!items) items = 1;

        var nav = parseInt($(this).attr("data-owl-nav"), 2);
        if (!nav) nav = 0;

        var dots = parseInt($(this).attr("data-owl-dots"), 2);
        if (!dots) dots = 0;

        var center = parseInt($(this).attr("data-owl-center"), 2);
        if (!center) center = 0;

        var stagepadding = parseInt($(this).attr("data-owl-stage-padding"), 200);
        if (!stagepadding) stagepadding = 0;

        var loop = parseInt($(this).attr("data-owl-loop"), 2);
        if (!loop) loop = 0;

        var margin = parseInt($(this).attr("data-owl-margin"), 30);
        if (!margin) margin = 0;

        var autoWidth = parseInt($(this).attr("data-owl-auto-width"), 2);
        if (!autoWidth) autoWidth = 0;

        var navContainer = $(this).attr("data-owl-nav-container");
        if (!navContainer) navContainer = 0;

        var autoplay = parseInt($(this).attr("data-owl-autoplay"), 2);
        if (!autoplay) autoplay = 0;

        var autoplayTimeOut = parseInt($(this).attr("data-owl-autoplay-timeout"), 10);
        if (!autoplayTimeOut) autoplayTimeOut = 5000;

        var autoHeight = parseInt($(this).attr("data-owl-auto-height"), 2);
        if (!autoHeight) autoHeight = 0;

        var fadeOut = $(this).attr("data-owl-fadeout");
        if (!fadeOut) fadeOut = 0;
        else fadeOut = "fadeOut";

        if ($("body").hasClass("rtl")) var rtl = true;
        else rtl = false;

        if (items === 1) {
            $(this).owlCarousel({
                navContainer: navContainer,
                animateOut: fadeOut,
                autoplayTimeout: autoplayTimeOut,
                autoplay: 1,
                autoheight: autoHeight,
                center: center,
                loop: loop,
                stagePadding: stagepadding,
                margin: margin,
                autoWidth: autoWidth,
                items: 1,
                nav: nav,
                dots: dots,
                rtl: rtl,
                navText: ['<i class="fal fa-chevron-left"></i>', '<i class="fal fa-chevron-right"></i>'],
            });
        }
        else {
            $(this).owlCarousel({
                navContainer: navContainer,
                animateOut: fadeOut,
                autoplayTimeout: autoplayTimeOut,
                autoplay: autoplay,
                autoheight: autoHeight,
                center: center,
                loop: loop,
                stagePadding: stagepadding,
                margin: margin,
                autoWidth: autoWidth,
                items: 1,
                nav: nav,
                dots: dots,
                rtl: rtl,
                navText: ['<i class="fal fa-chevron-left"></i>', '<i class="fal fa-chevron-right"></i>'],
                responsive: {
                    1368: {
                        items: items
                    },
                    992: {
                        items: 3
                    },
                    450: {
                        items: 2
                    },
                    0: {
                        items: 1
                    }
                }
            });
        }

        if ($(this).find(".owl-item").length === 1) {
            $(this).find(".owl-nav").css({ "opacity": 0, "pointer-events": "none" });
        }

    });
}



$('.custom-home').owlCarousel({
    loop: false,
    margin: 20,
    nav: true,
    navText: ['<i class="fal fa-chevron-right"></i>', '<i class="fal fa-chevron-left"></i>'],

    dots: false,
    rtl: true,
    items: 1.2,
    // responsive: {
    //     0: {
    //         items: 1
    //     },
    //     600: {
    //         items: 1
    //     },
    //     1000: {
    //         items: 1
    //     }
    // }
})

// popup

var $popupImage = $(".popup-image");

if ($popupImage.length > 0) {
    $popupImage.magnificPopup({
        type: 'image',
        fixedContentPos: false,
        gallery: { enabled: true },
        removalDelay: 300,
        mainClass: 'mfp-fade',
        callbacks: {
            // This prevents pushing the entire page to the right after opening Magnific popup image
            open: function () {
                $(".page-wrapper, .navbar-nav").css("margin-right", getScrollBarWidth());
            },
            close: function () {
                $(".page-wrapper, .navbar-nav").css("margin-right", 0);
            }
        }
    });
}

var $videoPopup = $(".video-popup");

if ($videoPopup.length > 0) {
    $videoPopup.magnificPopup({
        type: "iframe",
        removalDelay: 300,
        mainClass: "mfp-fade",
        overflowY: "hidden",
        iframe: {
            markup: '<div class="mfp-iframe-scaler">' +
                '<div class="mfp-close"></div>' +
                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                '</div>',
            patterns: {
                youtube: {
                    index: 'youtube.com/',
                    id: 'v=',
                    src: '//www.youtube.com/embed/%id%?autoplay=1'
                },
                vimeo: {
                    index: 'vimeo.com/',
                    id: '/',
                    src: '//player.vimeo.com/video/%id%?autoplay=1'
                },
                gmaps: {
                    index: '//maps.google.',
                    src: '%id%&output=embed'
                }
            },
            srcAction: 'iframe_src'
        }
    });
}
